.title {
  color: #9f0826;
  font-family: "Holtwood One SC";
  text-align: center;
  margin: 20px 0;
  font-size: 3rem;
}

.sub-title,
.description {
  font-size: 16px;
  text-align: justify;
  margin-top: 10px;
  color: #9f0826;
  font-family: "verdana";
}

.description {
  font-size: 20px;
  font-family: "Holtwood One SC";
}

.section-title {
  font-weight: bolder;
  text-align: center;
  margin: 20px 0;
  font-size: 2rem;
}

.bullet-point {
  margin-bottom: 10px;
}

.divider {
  margin: 20px 0;
  border-top: 1px solid #ddd;
}

.section-container {
  margin-top: 20px;
}

.image-section,
.image-section-brochure,
.image-section-model {
  text-align: center;
}

.video-container {
  text-align: center;
  margin-top: 20px;
}

.video-player {
  max-width: 100%;
  height: auto;
}
