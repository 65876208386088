/* Custom Fonts */
@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins-Regular.ttf");
  font-weight: 400;
}

@import url("https://fonts.googleapis.com/css2?family=Holtwood+One+SC&display=swap");

/** Default Css **/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  height: 12px;
  width: 5px;
  background: rgba(159, 8, 38, 0.377);
}

::-webkit-scrollbar-thumb {
  background: rgba(159, 8, 38, 1);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: rgba(246, 186, 24, 0.37608546836703427);
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}
::selection {
  color: rgba(0, 0, 0, 1);
  background: rgba(159, 8, 38, 1) 100%; /* WebKit/Blink Browsers */
}
::-moz-selection {
  color: rgba(0, 0, 0, 1);
  background: rgba(159, 8, 38, 1); /* Gecko Browsers */
}
body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: "Poppins" !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* img {
  width: 100%;
  height: auto;
} */

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/* Skills */
.skill {
  padding: 0 0 50px 0;
  position: relative;
}

.skill-bx {
  background-color: rgb(19 18 66);
  /* backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px); */
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.skill h2 {
  font-size: 35px;
  font-weight: 700;
}

.skill p {
  color: #b8b8b8;
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.chart-number {
  font-size: 5px;
  fill: white;
  line-height: 1;
  transform: translateY(-1.8em);
}
.chart-label {
  font-size: 5px;
  fill: white;
  transform: translateY(0.5em);
}
.figure-key [class*="shape-"] {
  margin-right: 8px;
}
.figure-key-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.figure-key-list li {
  margin: 5px auto;
}
.shape-circle {
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  text-transform: capitalize;
}
.chart-text {
  transform: translateY(0.25em);
}

.background-image-left {
  display: block;
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -4;
}
/* Footer */

.App {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  font-family: "Holtwood One SC", serif !important;
  font-weight: 400;
  font-style: normal;
}

nav.navbar.scrolled {
  padding: 5px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  margin-right: 40px;
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 15px;
  font-size: 15px;
  opacity: 0.75;
}
nav.navbar .navbar-nav .dropdown-toggle {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 15px;
  font-size: 15px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  color: rgba(159, 8, 38, 1) !important;
}
nav.navbar .navbar-nav a.dropdown-toggle:hover,
nav.navbar .navbar-nav .dropdown-menu a.dropdown-item:hover {
  opacity: 1;
  color: rgba(159, 8, 38, 1) !important;
  background-color: transparent;
}
nav.navbar .navbar-nav .dropdown-menu a.dropdown-item {
  color: white;
}
nav.navbar.scrolled .navbar-nav .dropdown-menu {
  background-color: #121212;
  border: unset;
}
nav.navbar .navbar-nav .dropdown-menu {
  background-color: transparent;
}
span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: rgba(159, 8, 38, 1);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: rgba(159, 8, 38, 1);
  border: 1px solid rgba(159, 8, 38, 1);
  border-radius: 40px;
  padding: 18px 34px;
  font-size: 15px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: rgba(159, 8, 38, 1);
  border-radius: 40px;
  left: 0;
  top: 0;
  z-index: -1;
}

.navbar-text button:hover {
  border-radius: 40px;
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  border-radius: 40px;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid rgba(159, 8, 38, 1);
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid rgba(159, 8, 38, 1);
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: rgba(159, 8, 38, 1);
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: rgba(159, 8, 38, 1);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: rgba(159, 8, 38, 1);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 50px 0 100px 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(240deg, #000000 30%, #011428 97%, #001428 0%);
}

.banner-title {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  color: white; /* Ensure the title is visible on dark background */
}

.banner p {
  color: #b8b8b8;
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 100%;
  margin: 0 auto; /* Center the paragraphs */
}

@media (max-width: 768px) {
  .banner-title {
    font-size: 36px;
  }

  .banner p {
    font-size: 14px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .banner-title {
    font-size: 28px;
  }

  .banner p {
    font-size: 12px;
    width: 100%;
  }
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

/* .txt-rotate > .wrap {
  border-right: 0.08em solid #666;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.progress-bar {
  z-index: 10000;
  color: rgba(159, 8, 38, 1) !important;
  background-color: rgba(159, 8, 38, 1) !important;
  transition: var(--bs-progress-bar-transition);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  transform-origin: 0%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/************ Projects Css ************/
.contact {
  padding: 60px 0 100px 0;
  position: relative;
}
.contact::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* animation-name: contactAnimation;
  animation-duration: 8s;
  animation-iteration-count: infinite; */
  z-index: 1;
}
/* @keyframes contactAnimation {
  0%   { background-color: linear-gradient(300deg, rgba(0,0,0,1) 16%, rgba(1,20,40,1));}
  25%  { background-color: linear-gradient(300deg, rgba(0,0,0,1) 16%, rgba(1,20,40,1));}
  50%  {background-color: linear-gradient(rgb(246, 187, 24));}
  75%  {background-color: linear-gradient(300deg, rgba(0,0,0,1) 16%, rgba(1,20,40,1));}
  100%   { background-color: linear-gradient(300deg, rgba(0,0,0,1) 16%, rgba(1,20,40,1));}
} */
.contact img {
  width: 92%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(1, 20, 40);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(159, 8, 38, 1);
  color: #121212;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  border-radius: 40px !important;
  background-color: rgba(1, 20, 40);
  color: rgba(159, 8, 38, 1);
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
  border-radius: 40px;
}
.contact form button:hover {
  color: rgba(1, 20, 40);
  border-radius: 40px;
}
.contact form button::before {
  content: "";
  background: rgba(159, 8, 38, 1);
  /* background: #e8b11f; */
  width: 0;
  border-radius: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.contact form button:hover::before {
  width: 100%;
  border-radius: 40px;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
}
.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: #151515;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(
    90.21deg,
    rgba(159, 8, 38, 1) -5.91%,
    rgba(159, 8, 38, 1) 111.58%
  );
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(159, 8, 38, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(159, 8, 38, 1);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(159, 8, 38, 1);
  border-bottom: 1px solid rgba(159, 8, 38, 1);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(159, 8, 38, 1);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(
    90.21deg,
    rgba(159, 8, 38, 1) -5.91%,
    rgba(159, 8, 38, 1) 111.58%
  );
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  color: #000;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
.sendbtn:hover {
  background-color: rgba(159, 8, 38, 1);
  border-radius: 40px;
}
.sendbtn {
  background-color: rgba(1, 20, 40, 1) 73% !important;
  color: rgba(246, 186, 24, 0.37608546836703427) 100%;
  border-radius: 40px;
}
.TextInfoContent-body-14 {
  color: #fff !important;
  margin-bottom: 7px !important;
}
.Features-Card {
  background-color: #ffffff10 !important;
  animation: fadeInLeft 2s;
}
.How-To-Buy-Card {
  background-color: #ffffff10 !important;
  animation: fadeInRight 2s;
}
/* services */
.services {
  /* min-height: 100vh; */
  width: 100%;
  padding-bottom: 80px;
}
.services .servicesTitle {
  height: 20vh;
  /* background-color: red; */
}
.services .servicesTitle .lineServices {
  width: 150px;
  height: 2px;
  background-color: rgba(159, 8, 38, 1);
  margin-bottom: 32px;
}
.services > * {
  width: 100%;
  height: 100%;
}
.fullServices {
  width: 100%;
  height: 100%;
}
.services .cardServices {
  height: 300px;
  /* background-color: rgb(18,18,24); */
  background: linear-gradient(
    300deg,
    rgba(0, 0, 0, 1) 16%,
    rgba(1, 20, 40, 1) 100%
  );
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in 0s;
}
.services .cardServices:hover {
  background-color: rgba(252, 10, 84, 0.07);
  /* transform: translateY(-15px); */
  transition: all 0.2s ease 0s;
  /* border: 2px solid rgba(159, 8, 38, 1); */
  box-shadow: rgba(159, 8, 38, 1) 2px 4px 80px;
}

.cardServices .first {
  position: relative;
  background-color: red;
  height: 100%;
  display: flex;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
}
.cardServices .first > img {
  height: 100%;
  /* border: 1px solid red; */
}
.priceOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s ease; /* Smooth transition */
  /* opacity: 1; */
}

.cardServices:hover .priceOverlay {
  opacity: 1;
}

/*end of  services Page*************************************************************************** */
.loadingItem {
  width: 10px;
  overflow: visible;
  stroke: rgba(159, 8, 38, 1);
  stroke-width: 20;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.loadingContainer {
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  width: 150px !important;
  height: 150px;
  display: flex;
  place-content: center;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
}
.marquee {
  height: 50px;
}
.navbarLogo {
  width: 10px;
  overflow: visible;
  stroke: #e81f1f;
  stroke-width: 5;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.toTopButton {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 999;
  background-color: #111;
  color: #fff;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
}
.toTopButton::before {
  content: "";
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0),
    rgba(1, 20, 40),
    rgba(159, 8, 38, 1)
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}
.toTopButton:active {
  color: #000;
}
.toTopButton:active::after {
  background: transparent;
}
.toTopButton:hover::before {
  opacity: 1;
}
.toTopButton::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.benefits {
  padding: 5% 0 5% 0;
}
.benefitsCard {
  font-size: 14px;
  margin: 25% 0 32% 0;
}
.benefitsCard:nth-last-child(1) {
  margin: 25% 0 10% 0;
}
.benefitsTitle {
  margin: 0 0 25px 0;
}
.benefitsBody {
  font-size: larger;
}
.benefitsSticky {
  margin-right: 10%;
}

/*start of  Road Map*************************************************************************** */
.roadMapImg {
  width: 600px;
}
.p-card {
  background: #ffffff00 !important;
  color: #ffffff !important;
  box-shadow: unset !important;
  border: unset !important;
}
.RoadMap-Phase5 {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.p-timeline-vertical.p-timeline-alternate
  .p-timeline-event
  .p-timeline-event-content {
  text-align: center !important;
}
.roadMapDesc {
  padding-top: 20px;
}
@media screen and (max-width: 991px) {
  .customized-timeline .p-timeline-event:nth-child(even) {
    flex-direction: row !important;
  }
  .customized-timeline
    .p-timeline-event:nth-child(even)
    .p-timeline-event-content {
    text-align: left !important;
  }
  .customized-timeline .p-timeline-event-opposite {
    flex: 0;
  }
  .customized-timeline .p-card {
    margin-top: 1rem;
  }
}
/* @media screen and (max-width: 737px) {
  .roadMapImg {
    width: calc(600px - 40vw);
  }
} */

/*end of  Road Map*************************************************************************** */

.mainBody {
  background: url("./assets/img/background.png") no-repeat center center;
  background-size: cover;
}

/* start of basic card for behind of projects */
/* Basic Card */
.basic-card {
  width: 300px;
  position: relative;

  /* -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  -o-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3); */
  /* box-shadow: #e8b11f 2px 4px 80px; */
}
/* .basic-card:hover{
  box-shadow: #e8b11f 2px 4px 80px;
  transition: all 0.2s ease 0s;

} */

.basic-card .card-content {
  padding: 30px;
}

.basic-card .card-title {
  font-size: 25px;
  font-family: "Open Sans", sans-serif;
}

.basic-card .card-text {
  line-height: 1.6;
}

.basic-card .card-link {
  padding: 25px;
  width: -webkit-fill-available;
}

.basic-card .card-link a {
  text-decoration: none;
  position: relative;
  padding: 10px 0px;
}

.basic-card .card-link a:hover {
  color: rgba(159, 8, 38, 1);
  transition: 0.4s ease;
}

.basic-card .card-link a:after {
  top: 30px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 0;

  -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.basic-card .card-link a:hover:after {
  width: 100%;
  left: 0;
}

.basic-card-aqua .card-content,
.basic-card .card-link a {
  color: #fff;
}

.basic-card-dark {
  background: transparent;
}

.basic-card-dark .card-title,
.basic-card-dark .card-link a {
  color: #eee;
}

.basic-card-dark .card-text {
  color: #dcdcdcdd;
}

.basic-card-dark .card-link {
  border-top: 1px solid #636363;
}

.basic-card-dark .card-link a:after {
  background: rgba(159, 8, 38, 1);
}
.marquee-container {
  height: 100%;
  overflow: hidden;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel .carousel-item img {
  width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: cover;
}

@media (max-width: 768px) {
  .carousel .carousel-item img {
    height: auto; /* Adjust height for smaller screens */
    object-fit: contain; /* Ensure the entire image fits within the container */
  }
}

/* .carousel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
} */

.sponsors {
  /* background-color: red; */
  position: relative;
}

.sponsors::after {
  content: "";
  position: absolute;
  bottom: -23px;
  left: calc(50% - 200px);
  width: 400px;
  height: 2px;
  background-color: rgba(159, 8, 38, 1);
}

.product-image img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.product-image img:hover {
  transform: scale(1.05);
}

.video-container {
  width: 100%;
}

.video-container iframe {
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* product Details part */

/* .product-image {
  margin-bottom: 20px;
} */

.slickSlide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slickSlide > img {
  width: 400px !important;
}

.product-image .slick-slide img {
  width: 400px !important;
  height: 300px;
  /* border: 1px solid #ddd; */
  /* border: 1px solid #9f0826; */
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.product-image .slick-slider {
  margin-bottom: 20px;
}

.product-image .slick-track {
  display: flex;
  align-items: center;
}

.product-image .slick-prev,
.product-image .slick-next {
  color: #fff;
  /* background-color: #9f0826; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.product-image .slick-prev {
  left: 10px;
}

.product-image .slick-next {
  right: 10px;
}

.product-image .slick-dots {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.product-image .slick-dots li {
  margin: 0 5px;
}

.product-image .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #9f0826;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-image .slick-dots li.slick-active button {
  background-color: #9f0826;
}

/* styles.css */

/* Container for the overview section */
.overview-container {
  margin: 20px; /* Adjust margin as needed */
  padding: 20px;
  border: 1px solid #ccc; /* Add a border for visual separation */
  border-radius: 10px; /* Rounded corners */
}

/* Style for each bullet point */
.bullet-point {
  margin-bottom: 10px; /* Space between bullet points */
  line-height: 1.5; /* Adjust line height for readability */
}

/* Style for the checkmark symbol */
.bullet-point::before {
  content: "√ "; /* Add the checkmark symbol before each bullet point */
  color: green; /* Change the color of the checkmark */
}

/* Additional styles for specific bullet points */
.advanced-tech {
  font-weight: bold; /* Make the text bold */
}

.matrix-heating {
  font-style: italic; /* Make the text italic */
}

/* Style for the divider */
.divider {
  margin: 20px 0; /* Adjust margin for spacing */
  border-bottom: 1px solid #ccc; /* Add a horizontal line */
}

/* New section styles */
.section-container {
  display: flex; /* Use flexbox to arrange the main and image sections */
  margin-top: 20px; /* Adjust margin for spacing */
  flex-wrap: wrap;
}

.main-section {
  flex: 1; /* Main section takes up half of the available space */
}

.image-section {
  flex: 1; /* Image section takes up the other half of the available space */
  /* Add any specific styles for the image section */
}

.section-title {
  font-size: 30px; /* Adjust font size as needed */
  margin-bottom: 20px; /* Space between title and text */
}

.section-text {
  font-size: 20px;
  line-height: 2; /* Adjust line height for readability */
}

.bold {
  font-weight: bold; /* Make specified words bold */
}

.image-section-borchure {
  width: 100%;
  height: auto;
}

.image-section-borchure > img {
  display: flex;
  width: 100%;
}

.image-section-model {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.image-section-model > img {
  display: flex;
  width: 50%;
}

.productDetails-list {
  line-height: 40px;
}

.productDetails-section ul li::marker {
  color: #9f0826;
}
